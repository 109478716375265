import React from 'react'

import Link from 'gatsby-link'
import Layout from '../../components/layout'
import BodyClassName from 'react-body-classname';
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import "animate.css/animate.css";
import ScrollAnimation from 'react-animate-on-scroll';
import styled from "@emotion/styled";
import CaseStudiesBlock from '../../components/CaseStudiesBlock'



const FourUp = styled.div`
display: grid;
grid-gap: 20px;
grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
`

const ThreeUp = styled.div`
display: grid;
grid-gap: 20px;
grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))
`


export default () => (
  <Layout>
  <PageTransition>
  <BodyClassName className="portfolio">
  
  <div className="page">
    <article>
      <section className="contentSection">
        <h1>Case Studies</h1>
        <p className="introText">The following case studies provide an overview of some of the work and how I've approached problems in my role as a design leader.</p>
        <ThreeUp>
        <CaseStudiesBlock howMany="6" />
        </ThreeUp>    
    
  </section>
  </article>
  </div>
  </BodyClassName>
</PageTransition>
</Layout>
)